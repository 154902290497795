import React, { useEffect, useState } from 'react';
import { ArrowLeft,ArrowRight } from 'react-bootstrap-icons'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useParams, Link } from "@reach/router";
import VideoPlayer from "../../videoplayer/VideoPlayer"
import { postAPICall } from '../../../Utils/Network';
import configs from '../../../Utils/configs';
import { navigate } from 'gatsby';  
import "./assets/styles/_index.scss"


const BcdEmprasaAboutSidebar = ({ isOpen, toggleRightSidebar }) => {
  const { developerId } = useParams();
  const [isShowMore, setIsShowMore] = useState(false);
  const [aboutContent, setAboutContent] = useState("");
  const [viewMoreContent, setViewMoreContent] = useState("");
  const [displayName, setDisplayName] = useState("");
 const [devID,setDevID] = useState();
 const [developerLogos, setDeveloperLogos] = useState([]);
 const [DeveloperBrouchureFile,setDeveloperBrouchureFile] =useState("");
 const [DeveloperVideo,setDeveloperVideo] = useState("");

  const videoUrl = configs.UTILITY_URL+DeveloperVideo;
  const thumbnailUrl = configs.UTILITY_URL+"/common/video-thumbnail.png"
  // const CommondeveloperLogo = configs.UTILITY_URL+developerlogo;

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    if (developerId) {
      FetchDetails();
  }  }, [developerId]);

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL();
    const param = {
      DeveloperName: developerId
    };
  
    postAPICall(
      url,
      param,
      res => {
        if (res && res.Data && res.Data.DeveloperDetails) {
          if (res.Data.DeveloperDetails.length === 0) {
            navigate("/404");
          } else {
            const developerDetails = res.Data.DeveloperDetails[0];
            setDisplayName(developerDetails.DisplayName);
            setAboutContent(developerDetails.AboutContent);
            setViewMoreContent(developerDetails.ViewMoreContent);
            setDevID(developerDetails.DeveloperId);

            const filteredFiles = res.Data.DeveloperFileDetails.filter(
              (file) => file.FileTypeId === 11
            );
  
            setDeveloperLogos(filteredFiles.map((file) => file.FileName));

            const developerFileBrouchure = res.Data.DeveloperFileDetails.find(file => file.FileTypeId === 12);
            setDeveloperBrouchureFile(developerFileBrouchure.FileName);

            const developerVideoFile = res.Data.DeveloperFileDetails.find(
              file => file.FileTypeId === 9
            )
            setDeveloperVideo(developerVideoFile.FileName)
          }
        }
      },
      err => {
       // // console.log(err);
      }
    );
  };

  const BroucherDownload = async () => {
    // Check if DeveloperBrouchureFile has a valid value
    if (!DeveloperBrouchureFile || DeveloperBrouchureFile.trim() === "") {
      console.log("No valid brochure available for download.");
      return; // Exit the function if there's no valid file
    }
  
    const pdfUrl = configs.UTILITY_URL + DeveloperBrouchureFile;
  
    try {
      const response = await fetch(pdfUrl);
  
      if (!response.ok) {
        throw new Error('Failed to fetch the file.');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = 'CorporateBrochure.pdf';
  
      document.body.appendChild(anchor);
      anchor.click();
  
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error("An error occurred while downloading the brochure:", error);
    }
  };
  
  
  
  

  return (
    <div className={`right-sidebar-about ${isOpen ? 'open' : ''}`}>

      <div className="aboutBackButton" onClick={toggleRightSidebar}><span>
        <Link to={`/developers/${developerId}/`}><ArrowLeft /> Back</Link></span>
      </div>

      <div className='AboutTitleImage'>
      {developerLogos.length === 1 ? (
      <Image
        src={`${configs.UTILITY_URL}${developerLogos[0]}`}
        alt="Developer Logo"
        className="headerImage m-0"
      />
    ) : (
      developerLogos.map((logo, index) => (
        <Image
          key={index}
          src={`${configs.UTILITY_URL}${logo}`}
          alt={`Developer Logo ${index + 1}`}
          className="headerImage m-0"
          fluid
        />
      ))
    )}
      {/* <Image
          src={CommondeveloperLogo}
          alt="Developer Logo"
        /> */}
      </div>

      <div className="AboutExpo">
      <Container>
          <Row>
            <div className="ReadMoreSection">
              <h4 className='m-0'>{displayName}</h4>
                <div className="mb-0 AboutExpoPara" dangerouslySetInnerHTML={{ __html: aboutContent }} />
              {isShowMore && (
                <div className="mb-0 AboutExpoPara" dangerouslySetInnerHTML={{ __html: viewMoreContent }} />
              )}

              <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>
                {isShowMore ? "Read Less" : "Read More"} <ArrowRight />
              </p>
            </div>
          </Row>
        </Container>
      </div>

      <div className="AboutRatingSection">
        <VideoPlayer
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
        />
      </div>


      <div className="AboutHallSection" >
        <Button onClick={BroucherDownload}>Download Brochure</Button>
      </div>
    </div>
  );
};

export default BcdEmprasaAboutSidebar;
