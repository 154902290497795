import React, { useState } from 'react';
import Modal from 'react-modal';
import "./assets/styles/_index.scss";
import { XCircleFill } from 'react-bootstrap-icons';
import configs from '../../Utils/configs';

const FileModal = ({ isOpen, onRequestClose, files = [] }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const fileIcons = {
    pdf: configs.UTILITY_URL + "/common/PdfLogo.png",
    mp4: configs.UTILITY_URL + "/common/VideoLogo.png",
    jpeg: configs.UTILITY_URL + "/common/ImageLogo.png",
    png: configs.UTILITY_URL + "/common/ImageLogo.png",
    jpg: configs.UTILITY_URL + "/common/ImageLogo.png",
  };

  const modalStyles = {
    overlay: {
      backgroundColor: '#00000050',
      zIndex: '9999',
    },
    content: {
      width: '30%',
      height: '30%',
      backgroundColor: '#ffffff',
      border: '0px solid',
      borderRadius: '10px',
      margin: '0 auto',
      top: '20%',
    }
  };

  const listStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  const iconStyle = {
    width: '25px',
    height: '25px',
    marginRight: '10px',
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const renderFilePreview = () => {
    if (!selectedFile) return null;

    const { type, url } = selectedFile;

    if (['pdf', 'mp4', 'jpeg', 'png', 'jpg'].includes(type)) {
      window.open(url, '_blank');
    }

    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}  
      contentLabel="File Modal"
      className="modalfilemodal"
      style={modalStyles}
    >
      <div className='header-file'>
        <button className='header-file-button' onClick={onRequestClose}>
          <XCircleFill style={{ color: "#3d106c", height: "22px", width: "22px" }} />
        </button>
      </div>
      <ul>
        {files.map((file, index) => {
          const { type, name } = file;
          const fileExtension = {
            pdf: 'pdf',
            mp4: 'mp4',
            jpeg: 'jpeg',
            png: 'png',
            jpg: 'jpg',
          }[type] || 'bin';
          const iconUrl = fileIcons[fileExtension] || configs.UTILITY_URL + "/common/PdfLogo.png"; 

          return (
            <li key={index} onClick={() => handleFileSelect(file)} style={listStyle}>
              <img src={iconUrl} alt={`${fileExtension} icon`} style={iconStyle} />
              {name}
            </li>
          );
        })}
      </ul>
      {renderFilePreview()}
    </Modal>
  );
};

export default FileModal;
