import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import BcdEmprasaDeveloperSidebar from "./BcdEmprasaDeveloperSidebar"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { Accordion, Card, Button } from "react-bootstrap"
import Slider from "react-slick"
import { ArrowLeft, GeoAlt, CurrencyRupee } from "react-bootstrap-icons"
import { useParams } from "@reach/router"
import { Link, navigate } from "gatsby"
import { postAPICall } from "../../../Utils/Network"
import configs from "../../../Utils/configs"
import LoadingModal from "../../loading/LoadingModal"
import ProptvStand from "../../proptvstand/ProptvStand"
import "./assets/styles/_index.scss"


function BcdEmprasaDeveloper() {
  const { developerId } = useParams()
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [devID, setDevID] = useState()
  const [BGImage, setBGImage] = useState("")
  const [BGMobileImage, setBGMobileImage] = useState("")
  const [cardItems, setCardItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false) 
  const [DeveloperStandVideo,setDeveloperStandVideo]= useState("");


  const backgroundImage = configs.UTILITY_URL + BGImage
  const backgroundMobileImage =
    configs.UTILITY_URL  + BGMobileImage
  const videoUrlStand = configs.UTILITY_URL + DeveloperStandVideo;
  const thumbnailUrlStand = configs.UTILITY_URL + "/homepage/prop-tv.png"
  const propGpt = configs.UTILITY_URL + "/common/propGPT.png"
  const project = configs.UTILITY_URL + "/icons/project-list.png"
  const about = configs.UTILITY_URL + "/icons/about-developer.png"
  const enquiry = configs.UTILITY_URL + "/icons/enquiry.png"

  useEffect(() => {
    // console.log(backgroundMobileImage);

    FetchDetails()

    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true) 
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: developerId,
    }

    postAPICall(
      url,
      param,
      res => {
        if (res && res.Status === "success") {
          if (res && res.Data && res.Data.DeveloperDetails) {
            if (res.Data.DeveloperDetails.length === 0) {
              navigate("/404")
            } else {
              const developerDetails = res.Data.DeveloperDetails[0]
              setDisplayName(developerDetails.DisplayName)
              setDevID(developerDetails.DeveloperId)

              const developerFileBGImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 3
              )
              setBGImage(developerFileBGImage.FileName)
              const developerFileBGMobileImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 1
              )
              setBGMobileImage(developerFileBGMobileImage.FileName)

              // Fetching card items from PropertyDetails
              const propertyDetails = res.Data.PropertyDetails
              console.log(propertyDetails);
              
              const cardItemsData = propertyDetails.map(property => ({
                id: property.LocationId,
                image:
                  configs.UTILITY_URL +
                  "/" +
                  property.DeveloperId +
                  "/" +
                  property.LocationId +
                  "/" +
                  property.FileName,
                price: formatPrice(property.Price),
                sqft: "/sqft",
                area: property.Area,
                link: `/developers/${developerId}/${property.RouteName}`,
              }))
              setCardItems(cardItemsData);

              const developerStandVideoFile = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 2
              )
              setDeveloperStandVideo(developerStandVideoFile.FileName)
            }
          }
          setIsLoading(false)
        } else {
          navigate("/404")
        }
      },
      err => {
        // console.log(err);
      }
    )
  }

  //format the price
  const formatPrice = price => {
    if (price >= 1000) {
      return new Intl.NumberFormat("en-IN").format(price)
    }
    return price
  }

  const BackgroundImage = {
    backgroundImage: isMobile
      ? `url(${backgroundMobileImage})`
      : `url(${backgroundImage})`,
    height: "100vh",
  }

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  }

   if (!isInitialized) {
    return null
  }

  return (
    <>
      <div className="ProjectListPageSectionBcd" style={BackgroundImage}>
      {!isMobile && (
        <Container fluid className="BoothBackSection">
          <Link to={`/developers/${developerId}/`}>
            <ArrowLeft /> Back
          </Link>

          <div className="EnquiryBoothSection">
            <Image src={enquiry} fluid className="" />
          </div>
        </Container>
      )}
        {!isMobile && (
        <div className="DeveloperSidebarSection">
          <BcdEmprasaDeveloperSidebar
            isOpen={isRightSidebarOpen}
            toggleRightSidebar={toggleRightSidebar}
          />
        </div>
        )}
        <ProjectCtaButton />
        {!isMobile && (
        <div className="proptv-stand-wrapper">
          <ProptvStand
            videoUrlStand={videoUrlStand}
            thumbnailUrlStand={thumbnailUrlStand}
          />
        </div>
        )}
         {!isMobile && (
        <Container fluid className="ProjectListFooterSection">
          <Row>
            <Col lg={4} className="p-0 textAlignCenter"></Col>
            <Col lg={4} className="p-0 textAlignCenter">
              <Row className="ProjectListBg">
                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                  <div className="ProjectList">
                    <Link to={`/developers/${developerId}/`}>
                      <p>
                        <Image src={project} fluid className="" /> Project List
                      </p>
                    </Link>
                  </div>
                </Col>
                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                  <div className="AboutDev">
                    <Link to={`/developers/${developerId}/about/`}>
                      <p>
                        <Image src={about} fluid className="" />{" "}
                        About&nbsp;Developer
                      </p>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="p-0 textAlignRight">
              <Image src={propGpt} fluid className="BoothLogoSection" />
            </Col>
          </Row>
        </Container>
         )}
           {isMobile && (
        <Accordion
          defaultActiveKey="0"
          className="ProjectListAccordionDeveloper"
         >
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Our Projects
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <section className="pt-0">
                  <Container className="p-0">
                    <Slider {...settings} className="">
                      {cardItems.map(card => (
                        <div
                          className="ProjectListAccordionSlider"
                          key={card.id}
                        >
                          <Row style={{ padding: "8px" }}>
                            <div className="ProjectListAccordionLeft">
                              <Link to={card.link}>
                                <Image
                                  src={card.image}
                                  fluid
                                  className=""
                                  alt="image"
                                />
                              </Link>
                            </div>
                            <div className="ProjectListAccordionRight">
                              <GeoAlt style={{ marginBottom: "10px" }} />{" "}
                              <span
                                className="projectlistCardArea-Name"
                                style={{
                                  display: " inline-block",
                                  width: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {card.area}
                              </span>
                              <br />
                              <CurrencyRupee className="projectlistCardPriceIcon" />
                              <b>{card.price}</b>{" "}
                              <span className="projectlistCardPriceRange">
                                {card.sqft}
                              </span>
                            </div>
                          </Row>
                        </div>
                      ))}
                    </Slider>
                  </Container>
                </section>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
           )}
      </div>
      <LoadingModal isLoading={isLoading} />

    </>
  )
}

export default BcdEmprasaDeveloper
