import React, { useEffect, useState } from "react"
// import "../assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import BcdCommonPropertySidebar from "./BcdCommonPropertySidebar"
import ProjectCtaButton from "../../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight, XCircleFill } from "react-bootstrap-icons"
import { Accordion, Card, Button } from "react-bootstrap"
import { useParams } from "@reach/router"
import ProptvStand from "../../../proptvstand/ProptvStand"
import { Nav, Navbar } from "react-bootstrap"
import ProptvVideoPlayer from "../../../proptvvideoplayer/ProptvVideoPlayer"
import { useLocation } from "@reach/router"
import { postAPICall } from "../../../../Utils/Network"
import configs from "../../../../Utils/configs"
import FileModal from "../../../filemodal/FileModal"
import DownloadFileModal from "../../../downloadfilemodal/DownloadFileModal"
import { Link, navigate } from "gatsby"
import Toast from "react-bootstrap/Toast"
import ProgressBar from "react-bootstrap/ProgressBar"
import LoadingModal from "../../../loading/LoadingModal"
// import "../../bcdemprasadeveloper/assets/styles/_index.scss"
import "../assets/styles/_index.scss"

function BcdCommonProperty() {
  const { developerId, propertyid } = useParams()

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [showVideoPopup, setShowVideoPopup] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [devID, setDevID] = useState()
  const [LocationLink, setLocationLink] = useState()
  const [DisplayName, setDisplayName] = useState()
  const [groupedFiles, setGroupedFiles] = useState({})
  const [files, setFiles] = useState([])
  const [BGImage, setBGImage] = useState("")
  const [BGMobileImage, setBGMobileImage] = useState("")
  const [propID, setPropertyId] = useState()
  const [Propertyvideo, setPropertyvideo] = useState("")
  const [showToast, setShowToast] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)
  const [DeveloperStandVideo, setDeveloperStandVideo] = useState("")

  const Brochureimage =
    configs.UTILITY_URL + "/common/details/brochure-image.png"
  const Floorplanimage =
    configs.UTILITY_URL + "/common/details/floor-plan-image.png"
  const Projectpriceimage =
    configs.UTILITY_URL + "/common/details/project-price-image.png"
  const Projectvideoimage =
    configs.UTILITY_URL + "/common/details/project-video-image.png"
  const videoUrlStand = configs.UTILITY_URL + DeveloperStandVideo
  const thumbnailUrlStand = configs.UTILITY_URL + "/homepage/prop-tv.png"
  const videoUrl = configs.UTILITY_URL + Propertyvideo

  const backgroundImage = configs.UTILITY_URL + BGImage
  const backgroundMobileImage = configs.UTILITY_URL + BGMobileImage

  const propGpt = configs.UTILITY_URL + "/common/propGPT.png"
  const project = configs.UTILITY_URL + "/icons/project-list.png"
  const about = configs.UTILITY_URL + "/icons/about-developer.png"
  const enquiry = configs.UTILITY_URL + "/icons/enquiry.png"

  const projects = configs.UTILITY_URL + "/icons/Projects-icon-mob.png"
  const abouticon = configs.UTILITY_URL + "/icons/Abt-icon-mob.png"
  const propversetv = configs.UTILITY_URL + "/icons/Proptv-icon-mob.png"
  const infodesk = configs.UTILITY_URL + "/icons/infodesk-icon-mob.png"

  useEffect(() => {
    FetchDetails()
    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true) // Set initialized to true after screen size check
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [developerId])

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(isRightSidebarOpen)
  }

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: developerId,
      ProjectName: propertyid,
    }

    postAPICall(
      url,
      param,
      res => {
        if (res && res.Status === "success") {
          if (res && res.Data && res.Data.DeveloperDetails) {
            if (res.Data.DeveloperDetails.length === 0) {
              navigate("/404")
            } else {
              const developerDetails = res.Data.DeveloperDetails[0]
              setDevID(developerDetails.DeveloperId)

              const groupedFiles = res.Data.GroupeofFileTypeId
              setGroupedFiles(groupedFiles)

              const PropertyFileDetails = groupedFiles["8"]?.[0]
              setPropertyId(PropertyFileDetails?.LocationId)

              const PropertyVideoFile = groupedFiles["4"]?.[0]
              setPropertyvideo(PropertyVideoFile?.FilePath)

              const Propertydetails = res.Data.PropertyDetails[0]
              setLocationLink(Propertydetails.LocationLink)
              setDisplayName(Propertydetails.DisplayName)

              const developerFileBGImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 3
              )
              setBGImage(developerFileBGImage.FileName)
              const developerFileBGMobileImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 1
              )
              setBGMobileImage(developerFileBGMobileImage.FileName)

              const developerStandVideoFile = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 2
              )
              setDeveloperStandVideo(developerStandVideoFile.FileName)
            }
          }
          setIsLoading(false)
        } else {
          navigate("/404")
        }
      },
      err => {
        // console.log(err)
      }
    )
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)
  }

  const closedownloadModal = () => {
    setIsDownloadModalOpen(false)
  }

  const handleViewButtonClick = fileTypeId => {
    const fileArray = groupedFiles[fileTypeId]

    if (fileArray.length === 1) {
      const fileUrl =
        configs.UTILITY_URL +
        "/" +
        devID +
        "/" +
        fileArray[0].LocationId +
        "/" +
        fileArray[0].FileName
      window.open(fileUrl, "_blank")
    } else if (fileArray.length > 1) {
      const toTheModel = fileArray.map(file => {
        const fileType = file.FileName.substring(
          file.FileName.lastIndexOf(".") + 1
        )

        return {
          name: file.FileName,
          type: fileType,
          url:
            configs.UTILITY_URL +
            "/" +
            devID +
            "/" +
            file.LocationId +
            "/" +
            file.FileName,
        }
      })
      setFiles(toTheModel)
      openModal()
    }
  }

  const handleDownloadButtonClick = async fileTypeId => {
    const fileArray = groupedFiles[fileTypeId]

    if (fileArray.length === 1) {
      const fileUrl = encodeURI(
        configs.UTILITY_URL +
          "/" +
          devID +
          "/" +
          fileArray[0].LocationId +
          "/" +
          fileArray[0].FileName
      )

      setSelectedFile(fileArray[0]) // Set selected file
      setIsDownloading(true) // Start downloading
      setShowToast(true) // Show toast

      try {
        const response = await fetch(fileUrl)
        if (!response.ok) {
          throw new Error("Network response was not ok.")
        }

        const totalSize = parseInt(response.headers.get("Content-Length"), 10)
        const reader = response.body.getReader()
        let receivedSize = 0
        const chunks = []

        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          chunks.push(value)
          receivedSize += value.length

          const progress = Math.round((receivedSize / totalSize) * 100)
          setDownloadProgress(progress) // Update progress bar
        }

        const blob = new Blob(chunks)
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = fileArray[0].FileName
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error("Error downloading file:", error)
      } finally {
        setIsDownloading(false) // Stop downloading
        setTimeout(() => setShowToast(false), 2000) // Hide toast after delay
      }
    } else if (fileArray.length > 1) {
      const toTheModel = fileArray.map(file => {
        const fileType = file.FileName.substring(
          file.FileName.lastIndexOf(".") + 1
        )
        return {
          name: file.FileName,
          type: fileType,
          url:
            configs.UTILITY_URL +
            "/" +
            devID +
            "/" +
            file.LocationId +
            "/" +
            file.FileName,
        }
      })
      setFiles(toTheModel)
      openDownloadModal()
    }
  }

  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(7),
      downloadButtonClick: () => handleDownloadButtonClick(7),
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(4),
      downloadButtonClick: () => handleDownloadButtonClick(4),
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(5),
      downloadButtonClick: () => handleDownloadButtonClick(5),
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(6),
      downloadButtonClick: () => handleDownloadButtonClick(6),
    },
  ]

  const location = useLocation()

  const href = location.pathname

  const openVideoPopup = () => {
    setShowVideoPopup(true)
  }

  const closeVideoPopup = () => {
    setShowVideoPopup(false)
  }

  const BackgroundImage = {
    backgroundImage: isMobile
      ? `url(${backgroundMobileImage})`
      : `url(${backgroundImage})`,
    height: "100vh",
  }

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      <Toast
        show={showToast}
        delay={3000}
        autohide={!isDownloading}
        className="custom-toast"
        style={{ zIndex: 10001 }}
        onClose={() => setShowToast(false)}
      >
        <div className="custom-toast-header">
          <strong className="me-auto">
            {isDownloading ? "Downloading..." : "Download Complete"}
          </strong>

          <XCircleFill
            className="custom-toast-close-btn"
            onClick={() => setShowToast(false)}
            style={{ color: "#ffff", height: "18px", width: "18px" }}
          />
        </div>

        <Toast.Body className="custom-toast-body">
          {isDownloading ? (
            <>
              <p>Downloading {selectedFile?.FileName}...</p>
              <ProgressBar
                animated
                now={downloadProgress}
                label={`${downloadProgress}%`}
                className="custom-progress-bar"
                variant="#3d106c"
              />
            </>
          ) : (
            <p>Download completed successfully!</p>
          )}
        </Toast.Body>
      </Toast>

      <div
        className="ProjectDetailPageSectionBcd ProjectCTASection"
        style={BackgroundImage}
      >
        {!isMobile && (
          <Container fluid className="BoothBackSection">
            <Link to={`/developers/${developerId}/`}>
              <ArrowLeft /> Back
            </Link>

            <div className="EnquiryBoothSection">
              <Image src={enquiry} fluid className="" alt="image" />
            </div>
          </Container>
        )}
        {!isMobile && (
          <div className="DeveloperPropertySidebarSection">
            <BcdCommonPropertySidebar
              isOpen={isRightSidebarOpen}
              toggleRightSidebar={toggleRightSidebar}
            />
          </div>
        )}
        <ProjectCtaButton />
        {isMobile && (
          <div className="ProjectResponsiveNavbarProperties">
            <Container className="PropertyContainerSection">
              <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    <Nav.Link href="#" className="menu-blue-navbar">
                      Menu
                    </Nav.Link>
                    <Nav.Link href={`/developers/${developerId}/`}>
                      <Image src={projects} fluid className="" alt="image" />{" "}
                      &nbsp;Projects
                    </Nav.Link>
                    <Nav.Link href={`/developers/${developerId}/about/`}>
                      <Image src={abouticon} fluid className="" alt="image" />{" "}
                      &nbsp;About Developer
                    </Nav.Link>
                    <Nav.Link onClick={openVideoPopup}>
                      <Image src={propversetv} fluid className="" alt="image" />{" "}
                      &nbsp;PropTv
                    </Nav.Link>
                    <Nav.Link href="/information-desk/">
                      <Image src={infodesk} fluid className="" alt="image" />{" "}
                      &nbsp;Infodesk
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
        )}

        {showVideoPopup && (
          <ProptvVideoPlayer
            ProptvVideoUrl={videoUrl}
            onClose={closeVideoPopup}
          />
        )}
        {!isMobile && (
          <div className="proptv-stand-wrapper">
            <ProptvStand
              videoUrlStand={videoUrlStand}
              thumbnailUrlStand={thumbnailUrlStand}
            />
          </div>
        )}
        {!isMobile && (
          <Container fluid className="ProjectDetailFooterSection">
            <Row>
              <Col lg={4} className="p-0 textAlignCenter"></Col>
              <Col lg={4} className="p-0 textAlignCenter">
                <Row className="ProjectDetailListBg">
                  <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                    <div className="ProjectDetailList">
                      <Link to={`/developers/${developerId}/`}>
                        <p>
                          <Image src={project} fluid className="" alt="image" />{" "}
                          Project List
                        </p>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    xs={7}
                    className="p-0 pr-3 pr-md-0 textAlignCenter"
                  >
                    <div className="AboutDev">
                      <Link to={`/developers/${developerId}/about/`}>
                        <p>
                          <Image src={about} fluid className="" alt="image" />{" "}
                          About&nbsp;Developer
                        </p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="p-0 textAlignRight">
                <Image
                  src={propGpt}
                  fluid
                  className="BoothLogoSection"
                  alt="image"
                />
              </Col>
            </Row>
          </Container>
        )}
      </div>
      {isMobile && (
        <Accordion
          defaultActiveKey="0"
          className="ProjectListAccordion ProjectDetailsList"
        >
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="" eventKey="0" style={{ color: "white",fontSize:"17px" }}>
                {DisplayName}
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {cardItems.map(card => (
                  <>
                    <Row className="m-0 mb-2">
                      <div className="ProjectListAccordionWidth">
                        <h6 className="projectdetailslist-card-title">
                          {card.title}
                        </h6>
                      </div>
                      <div className="ProjectListAccordionWidth">
                        <Button
                          className="card-buttons-download"
                          onClick={card.downloadButtonClick}
                        >
                          {card.button1}
                        </Button>
                      </div>
                      <div className="ProjectListAccordionWidth">
                        <Button
                          className="card-buttons-view"
                          onClick={card.viewButtonClick}
                        >
                          {card.button2}
                        </Button>
                      </div>
                    </Row>
                  </>
                ))}
                <div className="footer-link">
                  {LocationLink ? (
                    <Link target="_blank" to={LocationLink}>
                      <span>
                        Click here for more details <ArrowRight />
                      </span>
                    </Link>
                  ) : (
                    <span
                      style={{ cursor: "not-allowed", color: "gray" }} 
                      onClick={e => e.preventDefault()} 
                    >
                      Click here for more details <ArrowRight />
                    </span>
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {isModalOpen && (
        <FileModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          files={files}
        />
      )}

      {isDownloadModalOpen && (
        <DownloadFileModal
          isOpen={isDownloadModalOpen}
          onRequestClose={closedownloadModal}
          files={files}
        />
      )}
    </>
  )
}

export default BcdCommonProperty
