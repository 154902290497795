import React, { useEffect, useState } from 'react';
// import "../assets/styles/_index.scss"
import { ArrowLeft, ArrowRight, XCircleFill } from 'react-bootstrap-icons'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useParams } from "@reach/router";
import VideoPlayerTv from "../../../videoplayerTv/VideoPlayerTv"
import FileModal from '../../../filemodal/FileModal';
import DownloadFileModal from '../../../downloadfilemodal/DownloadFileModal';
import { postAPICall } from '../../../../Utils/Network';
import configs from '../../../../Utils/configs';
import { Link, navigate } from 'gatsby';
import Toast from 'react-bootstrap/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import "../../bcdemprasadeveloper/assets/styles/_index.scss"
import "../assets/styles/_index.scss"


const BcdCommonPropertySidebar = ({ isOpen, toggleRightSidebar, id }) => {
  const { developerId, propertyid } = useParams();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [devID, setDevID] = useState();
  const [PropertyLogo, setPropertyLogo] = useState("");
  const [propID, setPropertyId] = useState();
  const [Propertyvideo, setPropertyvideo] = useState("");
  const [LocationLink, setLocationLink] = useState();
  const [DisplayName, setDisplayName] = useState();
  const [groupedFiles, setGroupedFiles] = useState({}); 
  const [files, setFiles] = useState([]);
  const [showToast, setShowToast] = useState(false); 
  const [downloadProgress, setDownloadProgress] = useState(0); 
  const [isDownloading, setIsDownloading] = useState(false); 
  const [selectedFile, setSelectedFile] = useState(null); 


  const CommonPropertyLogo = configs.UTILITY_URL+ PropertyLogo;
  const videoUrl = configs.UTILITY_URL  + Propertyvideo;
  const thumbnailUrl = configs.UTILITY_URL + "/common/video-thumbnail.png";
  const Brochureimage = configs.UTILITY_URL + "/common/details/brochure-image.png";
  const Floorplanimage = configs.UTILITY_URL + "/common/details/floor-plan-image.png";
  const Projectpriceimage = configs.UTILITY_URL + "/common/details/project-price-image.png";
  const Projectvideoimage = configs.UTILITY_URL + "/common/details/project-video-image.png";

  useEffect(() => {
    FetchDetails(); 
  }, []);

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL();
    const param = {
      DeveloperName: developerId,
      ProjectName: propertyid
    };

    postAPICall(
      url,
      param,
      (res) => {
        if (res && res.Data && res.Data.DeveloperDetails) {
          if (res.Data.DeveloperDetails.length === 0) {
            navigate("/404");
          } else {
            const developerDetails = res.Data.DeveloperDetails[0];
            setDevID(developerDetails.DeveloperId);

            const groupedFiles = res.Data.GroupeofFileTypeId; 
            setGroupedFiles(groupedFiles); 

            const PropertyFileDetails = groupedFiles["8"]?.[0]; 
            setPropertyLogo(PropertyFileDetails?.FilePath);
            setPropertyId(PropertyFileDetails?.LocationId);

            const PropertyVideoFile = groupedFiles["4"]?.[0]; 
            setPropertyvideo(PropertyVideoFile?.FilePath);

            const Propertydetails = res.Data.PropertyDetails[0];
            setLocationLink(Propertydetails.LocationLink);
            setDisplayName(Propertydetails.DisplayName);
          }
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const closedownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const handleViewButtonClick = (fileTypeId) => {
    const fileArray = groupedFiles[fileTypeId];
  
    if (fileArray.length === 1) {
      const fileUrl = configs.UTILITY_URL + "/" + devID + "/" + fileArray[0].LocationId + "/" + fileArray[0].FileName;
      window.open(fileUrl, "_blank");
    } else if (fileArray.length > 1) {
      const toTheModel = fileArray.map(file => {
       
        const fileType = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);
      
        return {
          name: file.FileName,
          type: fileType, 
          url: configs.UTILITY_URL + "/" + devID + "/" + file.LocationId + "/" + file.FileName,
        };
      });
      setFiles(toTheModel);
      openModal();
    }
  };
  
  
const handleDownloadButtonClick = async (fileTypeId) => {
  const fileArray = groupedFiles[fileTypeId];

  if (fileArray.length === 1) {
    const fileUrl = encodeURI(configs.UTILITY_URL + "/" + devID + "/" + fileArray[0].LocationId + "/" + fileArray[0].FileName);

    setSelectedFile(fileArray[0]); // Set selected file for toast
    setIsDownloading(true); // Start downloading
    setShowToast(true); // Show toast

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const totalSize = parseInt(response.headers.get('Content-Length'), 10); 
      const reader = response.body.getReader();
      let receivedSize = 0;
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedSize += value.length;

        const progress = Math.round((receivedSize / totalSize) * 100);
        setDownloadProgress(progress); // Update progress bar
      }

      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = fileArray[0].FileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsDownloading(false); // Stop downloading
      setTimeout(() => setShowToast(false), 2000); // Hide toast after delay
    }
  } else if (fileArray.length > 1) {
    const toTheModel = fileArray.map((file) => {
      const fileType = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);
      return {
        name: file.FileName,
        type: fileType,
        url: configs.UTILITY_URL + "/" + devID + "/" + file.LocationId + "/" + file.FileName,
      };
    });
    setFiles(toTheModel);
    openDownloadModal();
  }
};


  
  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(7),
      downloadButtonClick: () => handleDownloadButtonClick(7)
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(4),
      downloadButtonClick: () => handleDownloadButtonClick(4)
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(5),
      downloadButtonClick: () => handleDownloadButtonClick(5)
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: () => handleViewButtonClick(6),
      downloadButtonClick: () => handleDownloadButtonClick(6),
    },
  ];

  return (
    <>
        
        <Toast
  show={showToast}
  delay={3000}
  autohide={!isDownloading}
  className="custom-toast"
  style={{ zIndex: 10001 }}
  onClose={() => setShowToast(false)} 
>
  <div className="custom-toast-header"> 
    <strong className="me-auto">
      {isDownloading ? 'Downloading...' : 'Download Complete'}
    </strong>
    <XCircleFill  className="custom-toast-close-btn" onClick={() => setShowToast(false)} style={{ color: '#ffff', height: '18px', width: '18px' }} />
  </div>

  <Toast.Body className="custom-toast-body">
    {isDownloading ? (
      <>
        <p>Downloading {selectedFile?.FileName}...</p>
        <ProgressBar
          animated
          now={downloadProgress}
          label={`${downloadProgress}%`}
          className="custom-progress-bar"
          variant='#3d106c'
        />
      </>
    ) : (
      <p>Download completed successfully!</p>
    )}
  </Toast.Body>
</Toast>




      <div className={`right-sidebar-details ${isOpen ? 'open' : ''}`}>
        <div className="projectdetailsBackButton">
          <Container>
            <Row>
              <Col lg={3}>
                <div className='mt-2'>
                  <span onClick={toggleRightSidebar}>
                    <Link to={`/developers/${developerId}/`}><ArrowLeft /> Back</Link>
                  </span>
                </div>
              </Col>
              <Col lg={6} style={{cursor:"default"}}>
                <h5 className='m-0'>{DisplayName}</h5>
              </Col>
              <Col lg={3} style={{cursor:"default"}}>
                <Image src={CommonPropertyLogo}  fluid className="headerImage m-0" alt="image"/>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="projectdetailslist">
          {cardItems.map((card) => (
            <div key={card.id}>
              <Container>
                <Col>
                  <Row lg={2}>
                    <h6 className='projectdetailslist-card-title'>{card.title}</h6>
                  </Row>
                  <Row lg={8}>
                    <Image
                      src={card.image}
                      alt={card.title}
                    />
                  </Row>
                  <Row lg={2} className='projectdetailslist-card-buttons'>
                    <Button className='card-buttons-download'  onClick={card.downloadButtonClick}>{card.button1}</Button>
                    <Button className='card-buttons-view' onClick={card.viewButtonClick}>{card.button2}</Button>
                  </Row>
                </Col>
              </Container>
            </div>
          ))}
          <h6 className="projectdetailslist-card-title" style={{marginLeft:"17px"}}>Prop TV</h6>
          <div style={{width:"92%",marginLeft:"15px",borderRadius:"2px"}}>
          <VideoPlayerTv
            className="projectdetailslist-tv-video"
            videoUrl={videoUrl}
            thumbnailUrl={thumbnailUrl}
            
          />
          </div>
          
          <div className="footer-link">
                  {LocationLink ? (
                    <Link target="_blank" to={LocationLink}>
                      <span>
                        Click here for more details <ArrowRight />
                      </span>
                    </Link>
                  ) : (
                    <span
                      style={{  color: "gray" }} 
                      onClick={e => e.preventDefault()} 
                    >
                      Click here for more details <ArrowRight />
                    </span>
                  )}
                </div>
        </div>
      </div>

      {isModalOpen && (
        <FileModal isOpen={isModalOpen} onRequestClose={closeModal} files={files} />
      )}

      {isDownloadModalOpen && (
        <DownloadFileModal isOpen={isDownloadModalOpen} onRequestClose={closedownloadModal} files={files} />
      )}
    </>
  );
};

export default BcdCommonPropertySidebar;
