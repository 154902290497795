import React, { useState } from 'react';
import Modal from 'react-modal';
import "./assets/styles/_index.scss";
import { XCircleFill } from 'react-bootstrap-icons';
import Toast from 'react-bootstrap/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import configs from '../../Utils/configs';

const DownloadFileModal = ({ isOpen, onRequestClose, files }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showToast, setShowToast] = useState(false);

  const fileIcons = {
    pdf: configs.UTILITY_URL + "/common/PdfLogo.png",
    mp4: configs.UTILITY_URL + "/common/VideoLogo.png",
    jpeg: configs.UTILITY_URL + "/common/ImageLogo.png",
    png:configs.UTILITY_URL + "/common/ImageLogo.png",
    jpg: configs.UTILITY_URL + "/common/ImageLogo.png",
  };
  
  const modalStyles = {
    overlay: {
      backgroundColor: '#00000050',
      zIndex: '9999',
    },
    content: {
      width: '30%',
      height: '30%',
      backgroundColor: '#ffffff',
      border: '0px solid',
      borderRadius: '10px',
      margin: '0 auto',
      top: '20%',
      zIndex: '10000',
    }
  };

  const listStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  const iconStyle = {
    width: '25px',
    height: '25px',
    marginRight: '10px',
  };

  const handleFileSelect = async (file) => {
    setSelectedFile(file);
    setIsDownloading(true);
    setShowToast(true);

    const { type, url, name } = file;

    const fileExtension = {
      pdf: 'pdf',
      mp4: 'mp4',
      jpeg: 'jpeg',
      png: 'png',
      jpg: 'jpg',
    }[type] || 'bin';
    const filename = name || `download.${fileExtension}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const totalSize = parseInt(response.headers.get('Content-Length'), 10); 
      const reader = response.body.getReader();
      let receivedSize = 0;
      const chunks = [];

     
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedSize += value.length;

        
        const progress = Math.round((receivedSize / totalSize) * 100);
        setDownloadProgress(progress);
      }

      const blob = new Blob(chunks);
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);

    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsDownloading(false);
      setDownloadProgress(100); 
      setTimeout(() => setShowToast(false), 2000);
    }
  };

  return (
    <>
      {/* Toast for download progress */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={!isDownloading}
        className="custom-toasts"
        style={{ zIndex: 10001 }} 
      >
        <div className="custom-toast-header"> 
    <strong className="me-auto">
      {isDownloading ? 'Downloading...' : 'Download Complete'}
    </strong>
    <XCircleFill className="custom-toast-close-btn" onClick={() => setShowToast(false)} style={{ color: '#ffff', height: '18px', width: '18px' }} />
  </div>
        <Toast.Body className="custom-toast-body">
          {isDownloading ? (
            <>
              <p>Downloading {selectedFile?.name}...</p>
              <ProgressBar
                animated
                now={downloadProgress}
                label={`${downloadProgress}%`}
                className="custom-progress-bar"
              />
            </>
          ) : (
            <p>Download completed successfully!</p>
          )}
        </Toast.Body>
      </Toast>

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={false}
        contentLabel="File Modal"
        className="modalfilemodal"
        style={modalStyles}
      >
        <div className="header-file">
          <span className="header-file-button" onClick={onRequestClose}>
            <XCircleFill style={{ color: '#3d106c', height: '22px', width: '22px' }} />
          </span>
        </div>
        <ul>
          {files.map((file, index) => {
            const { type, name } = file;
            const fileExtension = {
              pdf: 'pdf',
              mp4: 'mp4',
              jpeg: 'jpeg',
              png: 'png',
              jpg: 'jpg',
            }[type] || 'bin';
            const iconUrl = fileIcons[fileExtension] || configs.UTILITY_URL + "/common/PdfLogo.png"; 

            return (
              <li key={index} onClick={() => handleFileSelect(file)} style={listStyle}>
                <img src={iconUrl} alt={`${fileExtension} icon`} style={iconStyle} />
                {name}
              </li>
            );
          })}
        </ul>
      </Modal>
    </>
  );
};

export default DownloadFileModal;
