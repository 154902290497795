import React, { Component, useEffect, useState } from "react"
import "./assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "@reach/router"
import VideoPlayer from "../videoplayer/VideoPlayer"
import configs from "../../Utils/configs"
import { postAPICall } from "../../Utils/Network"
import { navigate } from "gatsby"
import LoadingModal from "../loading/LoadingModal"

const assets = {
  proptv: configs.UTILITY_URL + "/homepage/HallProptv.mp4",
  proptvThumbnail: configs.UTILITY_URL + "/homepage/prop-tv.png",
  proptvThumbnailmob: configs.UTILITY_URL + "/homepage/prop-tv-mob.png",
  logo: configs.UTILITY_URL + "/homepage/logo.png",
  hall: configs.UTILITY_URL + "/icons/exhibition-hall.png",
  desk: configs.UTILITY_URL + "/icons/information-desk.png",
}

function Hallsfel() {
  const [isMobile, setIsMobile] = useState(false);
  const [developers, setDevelopers] = useState([]);
  const [developers2, setDevelopers2] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  // const [isLoading, setIsLoading] = useState(true)

  const backgroundImage = configs.UTILITY_URL + "/homepage/hall-bg.jpg"
  const backgroundMobileImage =
    configs.UTILITY_URL + "/homepage/hall-bg-mob.png"

  useEffect(() => {
    
    FetchDetails()
    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true) 

    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL();
    const param = {
      DeveloperName: "",
    };
    // const headers = {
    //   auth_id: configs.AUTH_ID
    // };
  
    postAPICall(url, param, 
      res => {
        // console.log(res);
        
        if (res && res.Status === "success") {
          if (res.Data && res.Data.DeveloperDetails) {
            const hallImages = res.Data.HallImages;
            const sortedImages = hallImages.sort((a, b) => a.SortOrder - b.SortOrder);
  
            const developerImages = sortedImages.map(image => ({
              src: `${configs.UTILITY_URL}/${image.DeveloperId}/${image.FileName}`,
              link: `/developers/${image.RouteName}/`,
            }));
  
            
            setDevelopers(developerImages.slice(0, 12));
            setDevelopers2(developerImages.slice(12));
          }
  
          // stop loading
          // setIsLoading(false);
        } else {
          console.error("Failed to fetch data: ", res.data?.Status);
        }
      },
      err => {
        console.error("API Call failed: ", err);
      },
      // headers 
    );
  };
  

  const DeveloperList = ({ developers }) => (
    <ul className="HallSectionList">
      {developers.map((dev, index) => (
        <li key={index}>
          <Link to={dev.link}>
            <Image src={dev.src} fluid className="" alt="image"/>
          </Link>
        </li>
      ))}
    </ul>
  )

  const DeveloperList2 = ({ developers2 }) => (
    <ul className="HallSectionList">
      {developers2.map((dev, index) => (
        <li key={index}>
          <Link to={dev.link}>
            <Image src={dev.src} fluid className="" alt="image"/>
          </Link>
        </li>
      ))}
    </ul>
  )

  const BackgroundImage = {
    backgroundImage: isMobile
      ? `url(${backgroundMobileImage})`
      : `url(${backgroundImage})`,
    height: "100vh",
  }


  if (!isInitialized) {
    return null
  }


  return (
    <>
      {/* <LoadingModal isLoading={isLoading} /> */}
      <div className="HallSection d-none d-md-block" style={BackgroundImage}>
        <Container fluid>
          <Row className="HallSectionRow"> 
            <Col lg={3} xs={5} className="p-0 order-2 order-md-2 order-lg-1">
              <DeveloperList developers={developers} />
              <div className="ExhibitionHall">
                <Link to="/exhibition-hall/">
                  <p>
                    Exhibition&nbsp;Hall{" "}
                    <Image src={assets.hall} fluid className="" alt="image"/>
                  </p>
                </Link>
              </div>
            </Col>
            <Col lg={6} xs={12} className="p-0 order-1 order-md-1 order-lg-2">
              <div className="HallSectionMiddle">
                <VideoPlayer
                  videoUrl={assets.proptv}
                  thumbnailUrl={assets.proptvThumbnail}
                  fluid
                />
                <Link to="/">
                  <Image
                    src={assets.logo}
                    fluid
                    className="logo-section d-none-mob"
                    alt="image"
                  />
                </Link>
              </div>
            </Col>
            <Col lg={3} xs={5} className="p-0 order-3 order-md-3 order-lg-3">
              <DeveloperList2 developers2={developers2}  style={{backgroundColor:"white"}}/>
              <div className="InformationDesk">
                <Link to="/information-desk/">
                  <p>
                    <Image src={assets.desk} fluid className="" alt="image"/>{" "}
                    Information&nbsp;Desk
                  </p>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Mobile View */}
      <div className="HallSection d-block d-md-none" style={BackgroundImage}>
        <Container fluid>
          <Row className="HallSectionRow">
            <Col lg={3} xs={5} className="p-0 order-2 order-md-2 order-lg-1">
              <ul className="HallSectionList">
                {developers.map((dev, index) => (
                  <li key={index}>
                    <Link to={dev.link}>
                      <Image src={dev.src} fluid className="" alt="image"/>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="ExhibitionHall">
                <Link to="/exhibition-hall/">
                  <p>
                    Exhibition&nbsp;Hall{" "}
                    <Image src={assets.hall} fluid className="" alt="image"/>
                  </p>
                </Link>
              </div>
            </Col>
            <Col lg={6} xs={12} className="p-0 order-1 order-md-1 order-lg-2">
              <div className="HallSectionMiddle">
                <VideoPlayer
                  videoUrl={assets.proptv}
                  thumbnailUrl={assets.proptvThumbnail}
                  fluid
                />
                <Link to="/">
                  <Image
                    src={assets.logo}
                    fluid
                    className="logo-section d-none-mob"
                    alt="image"
                  />
                </Link>
              </div>
            </Col>
            <Col lg={3} xs={5} className="p-0 order-3 order-md-3 order-lg-3">
              <ul className="HallSectionList">
                {developers2.map((dev, index) => (
                  <li key={index}>
                    <Link to={dev.link}>
                      <Image src={dev.src} fluid className="" alt="image"/>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="InformationDesk">
                <Link to="/information-desk/">
                  <p>
                    <Image src={assets.desk} fluid className="" alt="image"/>{" "}
                    Information&nbsp;Desk
                  </p>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Hallsfel
