import React, { useEffect } from "react"
import "./assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import Popup from "../formpopup/FormPopup"
import { Button, Col, Row } from "react-bootstrap"
import { useState } from "react"
import { Formik } from "formik"
import configs from "../../Utils/configs"
import EnquiryPopup from "../enquirypopup/EnquiryPopUp"
import { postAPICall } from "../../Utils/Network"
import SuccessModal from "../SuccessModal/SuccesModal"
import { Nav, Navbar } from "react-bootstrap"
import ProptvVideoPlayer from "../proptvvideoplayer/ProptvVideoPlayer"
import { useLocation } from "@reach/router"
import { useParams } from "@reach/router"
import LoadingModal from "../loading/LoadingModal"
import { navigate } from "gatsby"
// import Swal from "sweetalert2"
import { XCircleFill } from "react-bootstrap-icons"
import Toast from "react-bootstrap/Toast"

function ProjectCtaButton() {
  const { developerId, propertyid } = useParams()
  const [isMobile, setIsMobile] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  const phoneNumber = "+919513438643"
  const [isOpenSV, setIsOpenSV] = useState(false)
  const [isOpenEN, setIsOpenEN] = useState(false)
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedTime, setSelectedTime] = useState("")
  const [selectedTimeId, setSelectedTimeId] = useState("")
  const [hoveredButton, setHoveredButton] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showVideoPopup, setShowVideoPopup] = useState(false)
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")

  const callnow = configs.UTILITY_URL + "/projectctabutton/call-now.png"
  const whatsapp = configs.UTILITY_URL + "/projectctabutton/whatsapp.png"
  const iconnect = configs.UTILITY_URL + "/projectctabutton/iconnect-button.png"
  const schedulenow = configs.UTILITY_URL + "/projectctabutton/schedule-now.png"
  const Enquiry = configs.UTILITY_URL + "/projectctabutton/enquiry-icon.png"

  const ExpoIcon = configs.UTILITY_URL + "/projectctabutton/Expo_Icon.png"
  const HomeIcon = configs.UTILITY_URL + "/projectctabutton/Home_Icon.png"
  const InfodeskIcon =
    configs.UTILITY_URL + "/projectctabutton/Infodesk_Icon.png"

  const enquirynow = configs.UTILITY_URL + "/projectctabutton/enquirynow.png"
  const enquirylogo = configs.UTILITY_URL + "/projectctabutton/EnquiryLogo.png"

  const projects = configs.UTILITY_URL + "/icons/Projects-icon-mob.png"
  const about = configs.UTILITY_URL + "/icons/Abt-icon-mob.png"
  const propversetv = configs.UTILITY_URL + "/icons/Proptv-icon-mob.png"
  const infodesk = configs.UTILITY_URL + "/icons/infodesk-icon-mob.png"

  const [timeoptions, setTimeOptions] = useState([])
  const whatsappLink =
    "http://wa.me/918185800800?text=Hi%20PropEx!%20I%27m%20looking%20for%20a%20property"

  const toIconnect = () => {
    window.open(
      "https://www.propex.ai/login?redirect=/index?act=iconnect&pfid=24"
    )
  }

  const togglePopupSV = () => {
    setIsOpenSV(!isOpenSV)
  }
  const togglePopupEN = () => {
    setIsOpenEN(!isOpenEN)
  }

  const currentDate = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date())

  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 14)
  const maxDateFormatted = maxDate.toISOString().split("T")[0]

  const handleTimeSelection = event => {
    setSelectedTime(event.target.value)
  }

  const href = location.pathname

  const valueToRemove = "about/"

  const updatedURL = href.replace(valueToRemove, "")

  const openVideoPopup = () => {
    setShowVideoPopup(true)
  }

  const closeVideoPopup = () => {
    setShowVideoPopup(false)
  }

  const handleButtonHover = buttonName => {
    setHoveredButton(buttonName)
  }

  const handleButtonLeave = () => {
    setHoveredButton(null)
  }

  const closeModal = () => {
    setShowSuccessModal(false)
  }

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true);



    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  if (!isInitialized) {
    return null
  }

  //Enquiry API_REQUEST
  const EnquiryFormSubmit = async values => {
    // console.log(values)
    var config = { "Access-Control-Allow-Origin": "*" }
    var param = {
      Name: values.fullname,
      Email: values.email,
      Phone: values.phonenumber,
      // CompanyName: values.selectedOption,
      Location: "",
      Budget: "",
      PickupDate: "",
      PickupTimeId: "",
      LeadSource: configs.LEAD_SOURCE_ENQUIRY,
      LandingPageId: configs.LANDING_PAGE_ID,
      PlatFormId: configs.PLATFORM_ID,
      IsIgnoreCRM: false,
    }
    var url = configs.ENQUIRY_API_URL()

    postAPICall(
      url,
      param,
      config,
      () => {
        // // console.log(res.data);
        setIsOpenEN(false)
        setShowSuccessModal(true)
      },
      () => {
        // // console.log(err);
      }
    )
  }

  const handleDateChange = event => {
    setIsLoading(true)
    const selectedDateValue = event.target.value
    setSelectedDate(selectedDateValue)

    const url = configs.TIME_API_URL()
    const param = {
      Date: selectedDateValue,
      PlatFormId: 24,
    }
    //  var config = { "Access-Control-Allow-Origin": "*" }

    postAPICall(
      url,
      param,
      // config,
      res => {
        // console.log(res.Data)

        if (res && res.Status === "success") {
          setIsLoading(false)
          setTimeOptions(res.Data)
        } else {
          // setIsLoading(true)
          navigate("/404")
        }
        // setShowSuccessModal(true)
      },
      err => {
        //  console.log(err)
      }
    )
  }

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      <Container fluid className="ProjectAboutMiddleSection">
        <div className="ProjectTopSection">
          <ul>
            <li>
              <Link to="/">
                <Image src={HomeIcon} fluid className="homeicon" alt="image" />
                <br />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/exhibition-hall/">
                <Image src={ExpoIcon} fluid className="hallicon" alt="image" />
                <br />
                <span>Hall</span>
              </Link>
            </li>
            <li>
              <Link to="/information-desk/">
                <Image
                  src={InfodeskIcon}
                  fluid
                  className="infoicon"
                  alt="image"
                />
                <br />
                <span>Info</span>
              </Link>
            </li>
          </ul>
        </div>

        <ul className="ProjectAboutMiddleSectionList">
          <li>
            <div className="ProjectAboutBoothList">
              <a href={`tel:${phoneNumber}`}>
                <p>
                  <Image src={callnow} fluid className="" alt="image" /> Call
                  Now
                </p>
              </a>
            </div>
          </li>
          <li>
            <div className="ProjectAboutBoothList" onClick={togglePopupSV}>
              <Link to="#">
                <p>
                  <Image src={schedulenow} fluid className="" alt="image" />{" "}
                  Schedule
                </p>
              </Link>
            </div>
          </li>
          <li>
            <div className="ProjectAboutBoothList" onClick={toIconnect}>
              <Link to="#">
                <p>
                  <Image src={iconnect} fluid className="" alt="image" />{" "}
                  iConnect
                </p>
              </Link>
            </div>
          </li>
          <li>
            <div className="ProjectAboutBoothList">
              <Link target="_blank" to={whatsappLink}>
                <p>
                  <Image src={whatsapp} fluid className="" alt="image" />{" "}
                  WhatsApp
                </p>
              </Link>
            </div>
          </li>
        </ul>
        {!isMobile && (
          <div fluid className="Project-enquirynows">
            <Link to="#">
              <div className="properytooltips">
                <Image
                  src={enquirynow}
                  onClick={togglePopupEN}
                  onMouseEnter={() => handleButtonHover("Enquiry Now")}
                  onMouseLeave={handleButtonLeave}
                  fluid
                  className=""
                  alt="image"
                />
                <span className="tooltiptext">Enquiry Now</span>
              </div>
            </Link>
          </div>
        )}
      </Container>

      <div className="ProjectResponsiveNavbar">
        <Container className="d-block d-md-none">
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link href="#" className="menu-blue-navbar">
                  Menu
                </Nav.Link>
                <Nav.Link href={`/developers/${developerId}/`}>
                  <Image src={projects} fluid className="" alt="image" />{" "}
                  &nbsp;Projects
                </Nav.Link>
                <Nav.Link href={`/developers/${developerId}/about/`}>
                  <Image src={about} fluid className="" alt="image" />{" "}
                  &nbsp;About Developer
                </Nav.Link>
                <Nav.Link onClick={openVideoPopup}>
                  <Image src={propversetv} fluid className="" alt="image" />{" "}
                  &nbsp;PropTv
                </Nav.Link>
                <Nav.Link href="/information-desk/">
                  <Image src={infodesk} fluid className="" alt="image" />{" "}
                  &nbsp;Infodesk
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>

      <ul className="ProjectAboutMiddleSectionMobile">
        <li>
          <div className="ProjectAboutBoothList">
            <a href={`tel:${phoneNumber}`}>
              <p>
                <Image src={callnow} fluid alt="Call Now" />
                <br />
                Call Now
              </p>
            </a>
          </div>
        </li>
        <li>
          <div className="ProjectAboutBoothList" onClick={togglePopupSV}>
            <Link to="#">
              <p>
                <Image src={schedulenow} fluid className="" alt="image" />{" "}
                <br />
                Schedule
              </p>
            </Link>
          </div>
        </li>
        <li>
          <div className="ProjectAboutBoothList" onClick={toIconnect}>
            <Link to="#">
              <p>
                <Image src={iconnect} fluid className="" alt="image" /> <br />
                iConnect
              </p>
            </Link>
          </div>
        </li>
        <li>
          <div className="ProjectAboutBoothList">
            <Link target="_blank" to={whatsappLink}>
              <p>
                <Image src={whatsapp} fluid className="" alt="image" /> <br />
                Whatsapp
              </p>
            </Link>
          </div>
        </li>
        <li>
          <div className="ProjectAboutBoothList">
            <div onClick={togglePopupEN}>
              <p>
                <Image src={Enquiry} fluid className="" alt="image" /> <br />{" "}
                Enquiry
              </p>
            </div>
          </div>
        </li>
      </ul>

      {showVideoPopup && (
        <ProptvVideoPlayer
          ProptvVideoUrl={configs.UTILITY_URL + "/homepage/HallProptv.mp4"}
          onClose={closeVideoPopup}
        />
      )}

      {/* SheduleVisit */}
      {isOpenSV && (
        <Popup
          content={
            <>
              <Formik
                initialValues={{
                  fullnameSV: "",
                  emailSV: "",
                  phonenumberSV: "",
                  locality: "",
                  selectedDate: "",
                  selectedTime: "",
                }}
                validate={values => {
                  const errors = {}
                  if (!values.fullnameSV) {
                    errors.fullnameSV = "Full name is required"
                  }
                  if (!values.phonenumberSV) {
                    errors.phonenumberSV = "Phone number is required"
                  } else if (values.phonenumberSV.length < 10) {
                    errors.phonenumberSV = "Phone number must be 10 digits"
                  }
                  if (!values.emailSV) {
                    errors.emailSV = "Email is required"
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.emailSV
                    )
                  ) {
                    errors.emailSV = "Invalid email address"
                  }
                  if (!values.selectedDate) {
                    errors.selectedDate = "Select Date"
                  }
                  if (!values.selectedTime) {
                    errors.selectedTime = "Select Time"
                  }
                  return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setIsLoading(true)
                  setTimeout(() => {
                    var param = {
                      Name: values.fullnameSV,
                      Email: values.emailSV,
                      Phone: values.phonenumberSV,
                      PickupDate: values.selectedDate,
                      PickupTimeId: selectedTimeId,
                      LeadSource: configs.LEAD_SOURCE_SCHEDULE,
                      LandingPageId: configs.LANDING_PAGE_ID,
                      PlatformId: configs.PLATFORM_ID,
                      IsIgnoreCRM: false,
                    }
                    var url = configs.SCHEDULE_API_URL()

                    postAPICall(
                      url,
                      param,
                      res => {
                        if (res && res.Status === "success") {
                          setIsOpenSV(false)
                          setIsLoading(false)
                          setShowSuccessModal(true)
                        } else {
                          setShowToast(true)
                          setToastMessage(res.Message || "Something went wrong")
                          setIsOpenSV(false)
                          setIsLoading(false)
                        }
                      },
                      () => {
                        // Handle error
                      }
                    )

                    setSubmitting(false)
                  })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="popup">
                      <h4 className="popup-header">Schedule Visit</h4>

                      <div className="position-relative">
                        <input
                          className="Popup-input"
                          type="text"
                          name="fullnameSV"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullnameSV}
                          placeholder="Full Name"
                        />
                        {errors.fullnameSV && touched.fullnameSV && (
                          <span className="error-text-schedule">
                            {errors.fullnameSV}
                          </span>
                        )}
                      </div>

                      <div className="position-relative">
                        <input
                          className="Popup-input"
                          type="tel"
                          name="phonenumberSV"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phonenumberSV}
                          placeholder="Phone Number"
                          maxLength={10}
                        />
                        {errors.phonenumberSV && touched.phonenumberSV && (
                          <span className="error-text-schedule">
                            {errors.phonenumberSV}
                          </span>
                        )}
                      </div>

                      <div className="position-relative">
                        <input
                          className="Popup-input"
                          type="email"
                          name="emailSV"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailSV}
                          placeholder="E-mail Id"
                        />
                        {errors.emailSV && touched.emailSV && (
                          <span className="error-text-schedule">
                            {errors.emailSV}
                          </span>
                        )}
                      </div>

                      <div className="popup-datetime">
                        <input
                          type="date"
                          id="myDateInput"
                          min={currentDate}
                          max={maxDateFormatted}
                          className="Popup-input-date"
                          onChange={e => {
                            handleChange(e)
                            handleDateChange(e) // Custom date handler if needed
                          }}
                          onBlur={handleBlur}
                          value={values.selectedDate}
                          name="selectedDate"
                        />

                        <select
                          value={values.selectedTime}
                          onChange={e => {
                            handleChange(e)
                            handleTimeSelection(e) // Custom time handler if needed
                          }}
                          onBlur={handleBlur}
                          className="Popup-input-date mr-0"
                          name="selectedTime"
                        >
                          <option value="">Choose time*</option>
                          {timeoptions.map(time => (
                            <option key={time.TimeId} value={time.TimeId}>
                              {time.TimeString}
                            </option>
                          ))}
                        </select>

                        <div className="position-relative">
                          {errors.selectedDate && touched.selectedDate && (
                            <span className="error-text-schedules">
                              {errors.selectedDate}
                            </span>
                          )}
                          {errors.selectedTime && touched.selectedTime && (
                            <span className="error-text-schedule">
                              {errors.selectedTime}
                            </span>
                          )}
                        </div>
                      </div>

                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className="Popup-submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          }
          handleClose={togglePopupSV}
        />
      )}

      {/* EnquiryNow */}
      {isOpenEN && (
        <EnquiryPopup
          content={
            <>
              <Container>
                <Row>
                  <Col className="enquiry-image-col" lg={6} md={6} sm={6}>
                    <Image src={enquirylogo} alt="image" />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="enquiry-content-text">
                    <Formik
                      initialValues={{
                        fullname: "",
                        email: "",
                        phonenumber: "",
                      }}
                      validate={values => {
                        const errors = {}
                        if (!values.fullname) {
                          errors.fullname = "Required"
                        } else if (!values.phonenumber) {
                          errors.phonenumber = "Required"
                        } else if (!values.email) {
                          errors.email = "Required"
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address"
                        }
                        return errors
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          EnquiryFormSubmit(values)

                          setSubmitting(false)
                        }, 400)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="popup">
                            <h4 className="popup-header">Let's Connect</h4>
                            <Container className="p-0">
                              <Row className="w-100 m-0">
                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="text"
                                    name="fullname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                    placeholder="Full Name"
                                  />
                                  <span className="error-text">
                                    {errors.fullname &&
                                      touched.fullname &&
                                      errors.fullname}
                                  </span>
                                </div>

                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="tel"
                                    name="phonenumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phonenumber}
                                    placeholder="Phone Number"
                                    maxLength={10}
                                  />
                                  <span className="error-text">
                                    {errors.phonenumber &&
                                      touched.phonenumber &&
                                      errors.phonenumber}
                                  </span>
                                </div>

                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="E-mail Id"
                                  />
                                  <span className="error-text">
                                    {errors.email &&
                                      touched.email &&
                                      errors.email}
                                  </span>
                                </div>

                                <div className="w-100">
                                  <Button
                                    type="submit"
                                    className="Popup-submit"
                                    disabled={isSubmitting}
                                  >
                                    Book Now
                                  </Button>
                                </div>
                              </Row>
                            </Container>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Container>
            </>
          }
          handleClose={togglePopupEN}
        ></EnquiryPopup>
      )}
      {showSuccessModal && <SuccessModal closeModal={closeModal} />}

      {showToast && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          className="custom-toasts"
          style={{
            zIndex: 999999,
            backgroundColor: "#dc3545",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          <div
            className="custom-toast-header"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong className="me-auto" style={{ marginRight: "auto" }}>
              <strong>{toastMessage}</strong>
            </strong>
            <XCircleFill
              onClick={() => setShowToast(false)}
              style={{
                color: "#ffffff",
                height: "18px",
                width: "18px",
                cursor: "pointer",
              }}
            />
          </div>
          <Toast.Body className="custom-toast-body">
            <b>Try Other Leads or Try Again Later</b>
          </Toast.Body>
        </Toast>
      )}
    </>
  )
}

export default ProjectCtaButton
