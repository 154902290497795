import React from "react";
import "./assets/styles/_index.scss"
import { ArrowsAngleContract, Cursor, XCircleFill } from "react-bootstrap-icons";

const FormPopup = props => {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}><XCircleFill style={{ color: "#3d106c" }}/></span>
        {props.content}
      </div>
    </div>
  );
};

export default FormPopup;