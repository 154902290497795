import React, { useEffect, useState } from "react"
import BcdEmprasaAboutSidebar from "./BcdEmprasaAboutSidebar"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons"
import { Accordion, Card, Button } from "react-bootstrap"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"
import configs from "../../../Utils/configs"
import { navigate } from "gatsby"
import { postAPICall } from "../../../Utils/Network"
import LoadingModal from "../../loading/LoadingModal"
import { useParams, Link } from "@reach/router"
import "./assets/styles/_index.scss"

function BcdEmprasaAbout() {
  const { developerId } = useParams()
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [aboutContent, setAboutContent] = useState("")
  const [viewMoreContent, setViewMoreContent] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [devID, setDevID] = useState()
  const [BGImage, setBGImage] = useState("")
  const [BGMobileImage, setBGMobileImage] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false) 
  const [DeveloperBrouchureFile,setDeveloperBrouchureFile] =useState("")
  const [DeveloperStandVideo,setDeveloperStandVideo]= useState("");
  const [DeveloperVideo,setDeveloperVideo] = useState("");


  const videoUrlStand = configs.UTILITY_URL + DeveloperStandVideo;
  const thumbnailUrlStand = configs.UTILITY_URL + "/homepage/prop-tv.png";
  const videoUrl = configs.UTILITY_URL +DeveloperVideo;
  const thumbnailUrl = configs.UTILITY_URL + "/common/video-thumbnail.png"
  const propGpt = configs.UTILITY_URL + "/common/propGPT.png"
  const project = configs.UTILITY_URL + "/icons/project-list.png"
  const about = configs.UTILITY_URL + "/icons/about-developer.png"
  const enquiry = configs.UTILITY_URL + "/icons/enquiry.png"
  const backgroundImage = configs.UTILITY_URL  + BGImage
  const backgroundMobileImage =
    configs.UTILITY_URL  + BGMobileImage

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(isRightSidebarOpen)
  }

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  }

  useEffect(() => {
    FetchDetails()

    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true) // Set initialized to true after screen size check
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [developerId])

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: developerId,
    }

    postAPICall(
      url,
      param,
      res => {
        if (res && res.Status === "success") {
          if (res && res.Data && res.Data.DeveloperDetails) {
            if (res.Data.DeveloperDetails.length === 0) {
              navigate("/404")
            } else {
              const developerDetails = res.Data.DeveloperDetails[0]
              setDisplayName(developerDetails.DisplayName)
              setAboutContent(developerDetails.AboutContent)
              setViewMoreContent(developerDetails.ViewMoreContent)
              setDevID(developerDetails.DeveloperId)

              const developerFileBGImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 3
              )
              setBGImage(developerFileBGImage.FileName)
              const developerFileBGMobileImage = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 1
              )
              setBGMobileImage(developerFileBGMobileImage.FileName)

              const developerFileBrouchure = res.Data.DeveloperFileDetails.find(file => file.FileTypeId === 12);
              setDeveloperBrouchureFile(developerFileBrouchure.FileName);

              const developerStandVideoFile = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 2
              )
              setDeveloperStandVideo(developerStandVideoFile.FileName);

              const developerVideoFile = res.Data.DeveloperFileDetails.find(
                file => file.FileTypeId === 9
              )
              setDeveloperVideo(developerVideoFile.FileName);
            }
          }
          setIsLoading(false)
        } else {
          navigate("/404")
        }
      },
      err => {
        //  // console.log(err)
      }
    )
  }

  const BroucherDownload = async () => {
    const pdfUrl = configs.UTILITY_URL + "/" + devID + "/" + DeveloperBrouchureFile;
  
    if (pdfUrl && pdfUrl.trim() !== "") {
      try {
        const response = await fetch(pdfUrl);
        
        if (!response.ok) {
          throw new Error('Failed to fetch the file.');
        }
  
        const blob = await response.blob();
        
        const url = window.URL.createObjectURL(blob);
        
        const anchor = document.createElement("a");
        anchor.href = url;
        
        anchor.download = 'CorporateBrochure.pdf';
        
       
        document.body.appendChild(anchor);
        anchor.click();
        
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
        
      } catch (error) {
        console.error("An error occurred while downloading the brochure:", error);
      }
    } else {
      console.log("No valid brochure available for download.");
    }
  };
  

  const BackgroundImage = {
    backgroundImage: isMobile
      ? `url(${backgroundMobileImage})`
      : `url(${backgroundImage})`,
    height: "100vh",
  }

  // Don't render until initialized (after screen size detection)
  if (!isInitialized) {
    return null
  }

  return (
    <>
      <div className="ProjectAboutPageSectionBcd" style={BackgroundImage}>
        {!isMobile && (
          <Container fluid className="BoothBackSection">
            <Link to={`/developers/${developerId}/`}>
              <ArrowLeft /> Back
            </Link>

            <div className="EnquiryBoothSection">
              <Image src={enquiry} fluid className="" alt="image" />
            </div>
          </Container>
        )}
        {!isMobile && (
          <div className="DeveloperAboutSidebarSection">
            <BcdEmprasaAboutSidebar
              isOpen={isRightSidebarOpen}
              toggleRightSidebar={toggleRightSidebar}
            />
          </div>
        )}
        <ProjectCtaButton />
        {!isMobile && (
          <div className="proptv-stand-wrapper">
            <ProptvStand
              videoUrlStand={videoUrlStand}
              thumbnailUrlStand={thumbnailUrlStand}
            />
          </div>
        )}
        {!isMobile && (
          <Container fluid className="ProjectAboutDetailFooterSection">
            <Row>
              <Col lg={4} className="p-0 textAlignCenter"></Col>
              <Col lg={4} className="p-0 textAlignCenter">
                <Row className="ProjectAboutDetailListBg">
                  <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                    <div className="ProjectAboutDetailList">
                      <Link to={`/developers/${developerId}/`}>
                        <p>
                          <Image src={project} fluid className="" /> Project
                          List
                        </p>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    xs={7}
                    className="p-0 pr-3 pr-md-0 textAlignCenter"
                  >
                    <div className="AboutDev">
                      <Link to={`/developers/${developerId}/about/`}>
                        <p>
                          <Image src={about} fluid className="" />{" "}
                          About&nbsp;Developer
                        </p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="p-0 textAlignRight">
                <Image src={propGpt} fluid className="BoothLogoSection" />
              </Col>
            </Row>
          </Container>
        )}
        {isMobile && (
          <Accordion
            defaultActiveKey="0"
            className="ProjectListAccordion ProjectDetailsList"
          >
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  {displayName}
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey="0">
                <Card.Body style={{height:"390px"}}>
                  <Tabs
                    defaultActiveKey="aboutus"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="aboutus" title="About Us">
                      <div
                        className="ReadMoreSection"
                        style={{ overflowY: "auto", maxHeight: "300px" }}
                      >
                        <h4 className="m-0">{displayName}</h4>
                        <div
                          className="mb-0 AboutExpoPara"
                          dangerouslySetInnerHTML={{ __html: aboutContent }}
                        />

                        {isShowMore && (
                          <div
                            className="mb-0 AboutExpoPara"
                            dangerouslySetInnerHTML={{
                              __html: viewMoreContent,
                            }}
                          />
                        )}
                        <p
                          className="m-0 AboutExpoButton"
                          onClick={toggleReadMoreLess}
                        >
                          {isShowMore ? "Read Less" : "Read More"}{" "}
                          <ArrowRight />
                        </p>

                        <div className="AboutHallSection">
                          <Button onClick={BroucherDownload}>Click here to download brochure</Button>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="video" title="Video">
                      <section className="pt-0">
                        <Container className="p-0">
                          <Slider {...settings} className="">
                            <div className="ProjectListAccordionSlider">
                              <VideoPlayer
                                videoUrl={videoUrl}
                                thumbnailUrl={thumbnailUrl}
                              />
                            </div>
                          </Slider>
                        </Container>
                      </section>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </div>
      <LoadingModal isLoading={isLoading} />
    </>
  )
}

export default BcdEmprasaAbout
