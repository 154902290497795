import React, { useEffect } from "react"
import Favicon from "react-favicon"
import { Helmet } from "react-helmet"
import TagManager from "react-gtm-module"
import Hallsfel from "../components/hallsfel/Hallsfel"
import { Router } from "@reach/router"
// import CommonDeveloper from "../components/developers/commondeveloper/CommonDeveloper"
// import CommonAbout from "../components/developers/commondeveloper/CommonAbout"
import CommonProperty from "../components/developers/commondeveloper/ourproperties/CommonProperty"
// import ExpoBooths from "../components/expobooths/ExpoBooths"
import InformationDesk from "../components/informationdesk/InformationDesk"
import NotFoundPage from "./404"
// import CommonDeveloperSidebar from "../components/developers/commondeveloper/CommonDeveloperSidebar"
// import CommonPropertySidebar from "../components/developers/commondeveloper/ourproperties/CommonPropertySidebar"
// import CommonAboutSidebar from "../components/developers/commondeveloper/CommonAboutSidebar"
import ExpoBoothsPage from "./exhibition-hall"
import BcdEmprasaDeveloper from "../components/developers/bcdemprasadeveloper/BcdEmprasaDeveloper"
import BcdEmprasaAbout from "../components/developers/bcdemprasadeveloper/BcdEmprasaAbout"
import BcdCommonProperty from "../components/developers/bcdemprasadeveloper/ourproperties/BcdCommonProperty"
// import BcdCommonPropertySidebar from "../components/developers/bcdemprasadeveloper/ourproperties/BcdCommonPropertySidebar"
import "../assets/styles/_index.scss"


const IndexPage = () => {
  const favicon_logo = require("../images/favicon.png")

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-J9C48LD" })
  }, [])

  return (
    <>
      <Favicon url={favicon_logo} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>Propverse - Powered by Propex.AI</title>
        <link rel="canonical" href="https://www.propverse.ai/" />
      </Helmet>

      <Router>
        <Hallsfel path="/" />

        <ExpoBoothsPage path="/exhibition-hall/" />
        <ExpoBoothsPage path="/developers" />
        <InformationDesk path="/information-desk" />

        <BcdEmprasaDeveloper path="/developers/:developerId" />

        <BcdEmprasaAbout path="/developers/:developerId/about" />

        <BcdCommonProperty path="/developers/:developerId/:propertyid" />
        {/* <BcdCommonPropertySidebar path="/developers/:developerId/:propertyid" /> */}

        <NotFoundPage path="/*" />
        <NotFoundPage path="/common-developer" />
        <NotFoundPage path="/developers/common-developer" />
        <NotFoundPage path="/developers/common-developer/commonproperty" />
      </Router>
    </>
  )
}

export default IndexPage;

// No Internet Screen Added

// import React, { useEffect, useState } from "react";
// import "../assets/styles/_index.scss";
// import Favicon from "react-favicon";
// import { Helmet } from "react-helmet";
// import TagManager from "react-gtm-module";
// import Hallsfel from "../components/hallsfel/Hallsfel";
// import { Router } from "@reach/router";
// import CommonDeveloper from "../components/developers/commondeveloper/CommonDeveloper";
// import CommonAbout from "../components/developers/commondeveloper/CommonAbout";
// import CommonProperty from "../components/developers/commondeveloper/ourproperties/CommonProperty";
// import ExpoBooths from "../components/expobooths/ExpoBooths";
// import InformationDesk from "../components/informationdesk/InformationDesk";
// import NotFoundPage from "./404";
// import CommonDeveloperSidebar from "../components/developers/commondeveloper/CommonDeveloperSidebar";
// import CommonPropertySidebar from "../components/developers/commondeveloper/ourproperties/CommonPropertySidebar";
// import CommonAboutSidebar from "../components/developers/commondeveloper/CommonAboutSidebar";
// import NoInternet from "../components/NoInternetScreen/NoInternet";

// const favicon_logo = require("../images/favicon.png");

// const IndexPage = () => {
//   const [isOnline, setIsOnline] = useState(true);

//   useEffect(() => {
//     TagManager.initialize({ gtmId: "GTM-J9C48LD" });

//     // initial online status
//     setIsOnline(navigator.onLine);

//     // listeners for online and offline status changes
//     const handleOnline = () => setIsOnline(true);
//     const handleOffline = () => setIsOnline(false);

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     // Clean up the event
//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   // If offline show
//   if (!isOnline) {
//     return (
//       <>
//         <Helmet>
//           <meta charSet="utf-8" />
//           <title>No Internet</title>
//         </Helmet>
//         <NoInternet />
//       </>
//     );
//   }

//   // If online
//   return (
//     <>
//       <Favicon url={favicon_logo} />

//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>Propverse - Powered by Propex.AI</title>
//         <link rel="canonical" href="https://www.propverse.ai/" />
//       </Helmet>

//       <Router>
//         <Hallsfel path="/" />
//         <ExpoBooths path="/exhibition-hall/" />
//         <ExpoBooths path="/developers" />
//         <InformationDesk path="/information-desk" />
//         <CommonDeveloper path="/developers/:developerId" />
//         <CommonDeveloperSidebar path="/developers/:developerId" />
//         <CommonAbout path="/developers/:developerId/about" />
//         <CommonAboutSidebar path="/developers/:developerId/about" />
//         <CommonProperty path="/developers/:developerId/:propertyid" />
//         <CommonPropertySidebar path="/developers/:developerId/:propertyid" />
//         <NotFoundPage path="/*" />
//         <NotFoundPage path="/common-developer" />
//         <NotFoundPage path="/developers/common-developer" />
//         <NotFoundPage path="/developers/common-developer/commonproperty" />
//       </Router>
//     </>
//   );
// };

// export default IndexPage;
