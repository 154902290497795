import React, { useEffect, useState } from 'react';
import { ArrowLeft, GeoAlt, CurrencyRupee } from 'react-bootstrap-icons'
import { Col, Container, Image, Row, Card } from 'react-bootstrap'
import { Link, navigate } from 'gatsby';
import { useParams } from "@reach/router";
import configs from '../../../Utils/configs';
import { postAPICall } from '../../../Utils/Network';
import "./assets/styles/_index.scss"



const BcdEmprasaDeveloperSidebar = ({ isOpen, toggleRightSidebar, id }) => {
  const { developerId, propertyid } = useParams();
  const [devID,setDevID] = useState();
  const [cardItems,setCardItems] =useState([]);
  const [developerLogos, setDeveloperLogos] = useState([]);
  
  // const CommondeveloperLogo= configs.UTILITY_URL+developerlogo;

  useEffect(() => {
    FetchDetails();
   }, [developerId]);
 
   const FetchDetails = () => {
     const url = configs.DEV_PROJ_API_URL();
     const param = {
       DeveloperName: developerId,
       ProjectName:propertyid
     };
   
     postAPICall(
       url,
       param,
       (res) => {
        // // console.log(res.data.Status);
        if (res.Data && res.Status === "success") {
         if (res && res.Data && res.Data.DeveloperDetails) {
           if (res.Data.DeveloperDetails.length === 0) {
             navigate("/404");
           } else {
             const developerDetails = res.Data.DeveloperDetails[0];
             setDevID(developerDetails.DeveloperId);
             const filteredFiles = res.Data.DeveloperFileDetails.filter(
              (file) => file.FileTypeId === 11
            );
  
            setDeveloperLogos(filteredFiles.map((file) => file.FileName));
   
             // Fetching card items from PropertyDetails
             const propertyDetails = res.Data.PropertyDetails;
             const cardItemsData = propertyDetails.map((property) => ({
               id: property.LocationId,
               image:configs.UTILITY_URL +"/" +property.DeveloperId +"/" +property.LocationId +"/" +property.FileName,  
               price: formatPrice(property.Price), 
               sqft: "/sqft", 
               area: property.Area,
               link: `/developers/${developerId}/${property.RouteName}`,
             }));
             setCardItems(cardItemsData);
           }
         }
         // setIsLoading(false)
       }else{
         navigate("/404");
       }
       },
       (err) => {
         // console.log(err);
       }
     );
   };
   
   // format the price
   const formatPrice = (price) => {
     if (price >= 1000) {
      
       return new Intl.NumberFormat('en-IN').format(price);
     }
     return price; 
   };
 

  return (
    <>
    <div className={`right-sidebars-list ${isOpen ? 'open' : ''}`}>

      <div className="projectlistBackButton" >
        <Container>
          <Row>
            <Col lg={3}>
              <div className='mt-2'>
                <span onClick={toggleRightSidebar}>
                <Link to="/exhibition-hall/"><ArrowLeft /> Back</Link></span>
              </div>
            </Col>
            <Col lg={6} style={{cursor:"default"}}>
              <h5 className='m-0'>Our Properties</h5>
            </Col>
            <Col lg={3} className='HeaderImage-Cols' style={{cursor:"default"}}>
            {developerLogos.length === 1 ? (
      <Image
        src={`${configs.UTILITY_URL}${developerLogos[0]}`}
        alt="Developer Logo"
        className="headerImage m-0"
      />
    ) : (
      developerLogos.map((logo, index) => (
        <Image
          key={index}
          src={`${configs.UTILITY_URL}${logo}`}
          alt={`Developer Logo ${index + 1}`}
          className="headerImage m-0"
          fluid
        />
      ))
    )}
            {/* <Image src={CommondeveloperLogo} fluid className="headerImage m-0" alt="image"/> */}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="projectlist">
        {cardItems.map((card) => (

          <Card className="projectlistCard">
            <Link to={card.link}>
              <Card.Img variant="top" src={card.image} className='projectlistCardImage' />
            </Link>
            <text variant="primary" className='projectlistCardText'>
              <Container>
                <Row>
                  <Col lg={7} className='projectlistCardArea'>
                    <GeoAlt /> <span className='projectlistCardArea-Name'>{card.area} </span>
                  </Col>
                  <Col lg={5} className='projectlistCardPrice'>
                    <CurrencyRupee className='projectlistCardPriceIcon' />{card.price} <span className='projectlistCardPriceRange'>{card.sqft}</span>
                  </Col>
                </Row>
              </Container>
            </text>
          </Card>

        ))}
      </div>


    </div>
    </>
  );
};

export default BcdEmprasaDeveloperSidebar;
