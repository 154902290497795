import React from "react"
import "../assets/styles/_custom.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Favicon from "react-favicon"
import Image from "react-bootstrap/Image"
import { Link } from "@reach/router";

const favicon_logo = require("../images/favicon.png");
const Error = require("../../utility/assets/common/404-Error.png")

const NotFoundPage = () => (  
  <>
    <Favicon url={favicon_logo} />

    <div className="NotFoundPage">
      <Container fluid>
        <Row className="NotFoundPageRow">
          <Col lg={6} xs={12} className="p-0">
            <Image src={Error} fluid className="" alt="image"/>
          </Col>
          <Col lg={6} xs={12} className="p-0">
            <div className="NotFoundPageRight">
              <h1>404</h1>
              <h6>Page Not Found</h6>
              <p>we're sorry, the page requested not to be found <br />please go back to home page</p>
              <button className="btn btn-default">
                <Link to="/">Go Home</Link>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
)

export default NotFoundPage
