import React from 'react';
import './assets/styles/_index.scss';
import ReactPlayer from 'react-player';
import { useRef } from 'react';
import { useState } from 'react';
import Image from "react-bootstrap/Image"

const ProptvVideoPlayer = ({ ProptvVideoUrl, onClose }) => {
  const [playing, setPlaying] = useState(true);
  const playerRef = useRef(null);

  const handleVideoEnded = () => {
    setPlaying(false);
  };

  const handlePlayClick = () => {
    setPlaying(true);
  };

  const closeIcon = require("/utility/assets/icons/close-icon.png")

  return (
    <div className="video-popup">
      <div className="video-container">
        <ReactPlayer
          url={ProptvVideoUrl}
          allowfullscreen
          ref={playerRef}
          controls={true}
          width="100%"
          height="auto"
          className="videoplayer"
          playing={playing}
          onEnded={handleVideoEnded}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}

        ></ReactPlayer>
      </div>
      {!playing && (
          <div className="video-card__play-button" onClick={handlePlayClick}>
            
          </div>
        )}
      <button className="close-btn" onClick={onClose}>
        <Image src={closeIcon} fluid className="" alt="image"/>
      </button>
    </div>
  );
};

export default ProptvVideoPlayer;